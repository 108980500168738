﻿'use strict';

import {
    $FILTER_AVAILABLE
} from './constants';

import {
    createNewHrefProductFilter
} from './product-filter'

import {
    getAllCheckedProductFilter
} from './product-set-state'


$FILTER_AVAILABLE.change(function () {

    $FILTER_AVAILABLE.prop('checked', false);
    $(this).prop('checked', true);

    let allChecked = getAllCheckedProductFilter();

    let newHref = createNewHrefProductFilter(allChecked);


    window.location.replace(newHref);

});


