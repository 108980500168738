﻿'use strict';

import Cookies from "js-cookie";

import {
    TOASTER_SUCCESS_BG_COLOR,
    TOASTER_ERROR_BG_COLOR
} from '../../constants';

import axios from 'axios';

import {
    AjaxRequestData
} from '../../global-function';

function OnSaveChangeCurrencySuccess(result) {

    if (result.status) {
        var path = window.location.pathname;
        window.location = path;

    } else {
        Toaster({
            text: result.message,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }

}

// Expose it to global scope.
window.OnSaveChangeCurrencySuccess = OnSaveChangeCurrencySuccess;



function OnSaveChangeCurrencyFailure(result) {
    Toaster({
        text: 'Currency cannot be change!',
        duration: 5000,
        backgroundColor: TOASTER_ERROR_BG_COLOR,
        position: 'right',
        gravity: 'bottom'
    });

}

// Expose it to global scope.

export function GET_CONVERSION_RATE(price) {

    console.log('converstion rate');
    var data = {
        price: price
    };

    var result = AjaxRequestData('/umbraco/surface/currency/ConversionRate', data);
    return result.conversionPrice;

}

export function GET_CURRENCY_SYMBOL() {
    console.log('get currency symbol');

    var data = Cookies.get("__currencySymbol");
    return data;
}

