﻿'use strict';

// toaster color
import {
    TOASTER_ERROR_BG_COLOR,
    TOASTER_SUCCESS_BG_COLOR
} from '../../constants';

// constants
import {
    LOGOUT_ENDPOINT_URL,
    $REGISTER_BUTTON_ELEMENTS,
    $EDIT_PROFILE_BUTTON_ELEMENTS,
    $RESET_PASSWORD_BUTTON_ELEMENTS,
    $RESET_PASSWORD_FORM_ELEMENT,
    $LOGIN_BUTTON_ELEMENTS,
    $LOGOUT_BUTTON_ELEMENTS,
    LOGIN_ERROR_MESSAGE,
    REGISTER_ERROR_MESSAGE,
    RESET_PASSWORD_SUCCESS_MESSAGE,
    $FAILED_PASSWORD_ATTEMPT_TEXT,
    MAX_FAILED_PASSWORD_ATTEMPT
} from './constants';

/*
 * ============================
 * Function handlers for REGISTER
 * AJAX (Begin, Success, Failure).
 * 
 * The form was handled by MVC C#
 * AJAX.beginfrom Razor helper.
 * 
 * These function was supposed to
 * be used as the value of
 * the corresponding events
 * value of the helper.
 * ============================
 */

/**
 * Handler when register ajax was about
 * to be run.
 */
function OnRegisterBegin() {
    console.log('sending...');

    $REGISTER_BUTTON_ELEMENTS
        .prop('disabled', true)
        .addClass('disabled');
}

// Expose it to global scope
window.OnRegisterBegin = OnRegisterBegin;

/**
 * Handler when register was a success.
 */
function OnRegisterSuccess(res) {
    // redirecting to profile
    //window.location.href = SUCCESS_REDIRECT_URL;
    window.location.reload();
}

// Expost it to global scope
window.OnRegisterSuccess = OnRegisterSuccess;

/**
 * Handler when register was a failure.
 */
function OnRegisterFailure(err) {
    $REGISTER_BUTTON_ELEMENTS
        .prop('disabled', false)
        .removeClass('disabled');

    if (err.status == 500) {
        Toaster({
            text: REGISTER_ERROR_MESSAGE,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
    else {
        Toaster({
            text: err.statusText,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
}
// Expose to global scope.
window.OnRegisterFailure = OnRegisterFailure;

/*
 * ============================
 * Function handlers for REGISTER
 * AJAX (Begin, Success, Failure)
 * .\END
 * ============================
 */

/*
 * ============================
 * Function handlers for EDIT PROFILE
 * AJAX (Begin, Failure, Success)
 * ============================
 */

/**
 * Handler when edit profile 
 * were a success.
 */
function OnEditProfileBegin() {
    $EDIT_PROFILE_BUTTON_ELEMENTS
        .prop('disabled', true)
        .addClass('disabled');
}

// Expose it to global scope.
window.OnEditProfileBegin = OnEditProfileBegin;

/**
 * Handler when edit profile
 * were a failure.
 * @param {jqxhr} err - Jqxhr object returned from the server
 */
function OnEditProfileFailure(err) {
    $EDIT_PROFILE_BUTTON_ELEMENTS
        .prop('disabled', false)
        .remove('disabled');

    Toaster({
        text: err.statusText,
        duration: 5000,
        gravity: 'bottom',
        position: 'right',
        backgroundColor: TOASTER_ERROR_BG_COLOR
    });
}

// Expose it to global scope.
window.OnEditProfileFailure = OnEditProfileFailure;

function OnEditProfileSuccess() {
    // refreshing the page
    window.location.reload();
}

// Expose it to global scope.
window.OnEditProfileSuccess = OnEditProfileSuccess;
/*
 * ============================
 * Function handlers for EDIT PROFILE
 * AJAX (Begin, Failure, Success)
 * .\END
 * ============================
 */

/*
 * ============================
 * Function handlers for RESET PASSWORD
 * AJAX (Begin, Failure, Success)
 * ============================
 */
function OnResetPasswordBegin() {
    // disabling the button
    $RESET_PASSWORD_BUTTON_ELEMENTS
        .prop('disabled', true)
        .addClass('disabled');
}

// Expose it to global scope.
window.OnResetPasswordBegin = OnResetPasswordBegin;

function OnResetPasswordFailure(err) {
    // enabling the submit button
    $RESET_PASSWORD_BUTTON_ELEMENTS
        .prop('disabled', true)
        .removeClass('disabled');

    // increment failed password attempt
    let isSuccess = IncrementFailedAttempt();

    if (isSuccess) {
        // show toast
        Toaster({
            text: err.statusText,
            duration: 5000,
            gravity: 'bottom',
            position: 'right',
            backgroundColor: TOASTER_ERROR_BG_COLOR,
        });
    }
    else {
        // show toast
        Toaster({
            text: 'Maximum attempt reached!',
            duration: 5000,
            gravity: 'bottom',
            position: 'right',
            backgroundColor: TOASTER_ERROR_BG_COLOR,
        });
    }
}

function IncrementFailedAttempt() {
    // current counter
    let currentCounter = parseInt($FAILED_PASSWORD_ATTEMPT_TEXT.data('number'));

    if (currentCounter >= MAX_FAILED_PASSWORD_ATTEMPT) {
        return false;
    }
    else {
        // increment the data
        $FAILED_PASSWORD_ATTEMPT_TEXT.data('number', currentCounter++);

        // change the display
        $FAILED_PASSWORD_ATTEMPT_TEXT.text($FAILED_PASSWORD_ATTEMPT_TEXT.data('number'));

        // logging
        console.log($FAILED_PASSWORD_ATTEMPT_TEXT.data('number'));

        return true;
    }
}

// Expose it to global scope.
window.OnResetPasswordFailure = OnResetPasswordFailure;

function OnResetPasswordSuccess() {
    // reset the form
    $RESET_PASSWORD_FORM_ELEMENT.find("input[type=password], textarea").val("");

    // reset failed attempt
    ResetFailedPasswordAttempt();

    // show toast
    Toaster({
        text: RESET_PASSWORD_SUCCESS_MESSAGE,
        duration: 5000,
        gravity: 'bottom',
        position: 'right',
        backgroundColor: TOASTER_SUCCESS_BG_COLOR,
    });
}

function ResetFailedPasswordAttempt() {
    // hide it
    $FAILED_PASSWORD_ATTEMPT_TEXT.parent().addClass('d-none');

    // resetting the data
    $FAILED_PASSWORD_ATTEMPT_TEXT.data('number', 0);

    // resetting the text
    $FAILED_PASSWORD_ATTEMPT_TEXT.text('0');
}

// Expose it to global scope.
window.OnResetPasswordSuccess = OnResetPasswordSuccess

/*
 * ============================
 * Function handlers for RESET PASSWORD
 * AJAX (Begin, Failure, Success)
 * .\END
 * ============================
 */

/*
 * ============================
 * Function handlers for LOGIN
 * AJAX (Begin, Success, Failure)
 * ============================
 */

/**
 * Handler when login form ajax
 * was about to be run.
 */
function OnLoginBegin() {
    $LOGIN_BUTTON_ELEMENTS
        .prop('disabled', true)
        .addClass('disabled');
}

// Expose to global scope.
window.OnLoginBegin = OnLoginBegin;

/**
 * Handler when login were
 * a failure.
 * @param {jqxhr} err - The error given back from the server.
 */
function OnLoginFailure(err) {
    $LOGIN_BUTTON_ELEMENTS
        .prop('disabled', false)
        .removeClass('disabled');

    if (err.status == 500) {
        Toaster({
            text: LOGIN_ERROR_MESSAGE,
            duration: 5000,
            backgroundColor: "linear-gradient(135deg, rgba(255,136,136,1) 0%, rgba(230,70,70,1) 100%)",
            position: 'right',
            gravity: 'bottom'
        });
    }
    else {
        Toaster({
            text: err.statusText,
            duration: 5000,
            backgroundColor: "linear-gradient(135deg, rgba(255,136,136,1) 0%, rgba(230,70,70,1) 100%)",
            position: 'right',
            gravity: 'bottom'
        });
    }
}

// Expose it to global scope.
window.OnLoginFailure = OnLoginFailure;

/**
 * Handler when login were
 * a success.
 */
function OnLoginSuccess() {
    window.location.reload();
}

// Expose it to global scope.
window.OnLoginSuccess = OnLoginSuccess;

/*
 * ============================
 * Function handlers for LOGIN
 * AJAX (Begin, Success, Failure)
 * .\END
 * ============================
 */




/*
 * ============================
 * Handler for logout functionality
 * to be attached to an element for
 * clicks. Should be a button or an
 * anchor.
 * ============================
 */
function Logout() {

    $.ajax({
        url: LOGOUT_ENDPOINT_URL,
        method: 'GET',
        context: window,
        success: function (res) {
            window.location.href = "/";
        },
        error: function (err) {

            if (err.status == 500) {
                Toaster({
                    text: err.statusText,
                    duration: 5000,
                    gravity: 'bottom',
                    position: 'right',
                    backgroundColor: TOASTER_ERROR_BG_COLOR,
                });
            }

            Toaster({
                text: 'Failed to logout!',
                duration: 5000,
                gravity: 'bottom',
                position: 'right',
                backgroundColor: TOASTER_ERROR_BG_COLOR,
            });
        }
    });
}

if ($LOGOUT_BUTTON_ELEMENTS.length) {
    $LOGOUT_BUTTON_ELEMENTS
        .attr('style', 'cursor: pointer !important;')
        .on('click', Logout)
}

// address
import './address';

// forgot password
import './forgot-password';

// recreate password
import './recreate-password';

// subscription
import './subscription';

// subscription
import './mailchimp-subscription';