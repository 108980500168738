﻿'use strict';

import axios from 'axios';

import {
    TOASTER_ERROR_BG_COLOR,
    TOASTER_SUCCESS_BG_COLOR,
    TOASTER_SUCCESS_BG_COLOR_ALT
} from '../../constants';

import {
    $SAVE_MAILCHIMP_SUBSCRIPTION,
    MAILCHIMP_SUBSCRIBE_URL,
    $MAILCHIMP,
    SUCCESS_MAILCHIMP_SUBSCRIBE
} from './constants';

// ???
$MAILCHIMP.bind("enterKey", function (e) {
    subscribe();
});

$MAILCHIMP.keyup(function (e) {
    if (e.keyCode == 13) {
        $(this).trigger("enterKey");
    }
});

$SAVE_MAILCHIMP_SUBSCRIPTION
    .click(function () {
        subscribe();
    });

function subscribe() {
    let data = {
        email: $MAILCHIMP.val()
    }

    axios.post(MAILCHIMP_SUBSCRIBE_URL, data)
        .then(res => {

            if (res.status == 200) {
                Toaster({
                    text: SUCCESS_MAILCHIMP_SUBSCRIBE,
                    backgroundColor: TOASTER_SUCCESS_BG_COLOR,
                    duration: 5000,
                    gravity: 'bottom',
                    position: 'right'
                });
                $MAILCHIMP.val("");
            }

        }).catch(err => {
            Toaster({
                text: err.response.statusText,
                backgroundColor: TOASTER_ERROR_BG_COLOR,
                duration: 5000,
                gravity: 'bottom',
                position: 'right'
            });
        });
}
