﻿/*
 * ========================
 * CONSTANTS
 * ========================
 */
import {
    $CONTACT_US_FORM,
    $CONTACT_US_SUBMIT_BUTTON,
    CONTACT_SUCCESS_MESSAGE
} from './constants';

import {
    TOASTER_ERROR_BG_COLOR,
    TOASTER_SUCCESS_BG_COLOR
} from '../../constants';
/*
 * ========================
 * CONSTANTS .\END
 * ========================
 */

/*
 * ========================
 * Event handlers for CONTACT
 * US AJAX (Begin, Failure,
 * SUCCESS).
 * ========================
 */
function OnContactUsBegin() {
    console.log('contact us begin');
    $CONTACT_US_SUBMIT_BUTTON
        .attr('disabled', true)
        .addClass('disabled');
}

// expose it to global scope.
window.OnContactUsBegin = OnContactUsBegin;

function OnContactUsFailure(err) {
    console.log('contact us fail');
    $CONTACT_US_SUBMIT_BUTTON
        .attr('disabled', false)
        .removeClass('disabled');


    Toaster({
        text: err.statusText,
        gravity: 'bottom',
        position: 'right',
        duration: 5000,
        backgroundColor: TOASTER_ERROR_BG_COLOR
    });
}

// expose it to global scope.
window.OnContactUsFailure = OnContactUsFailure;

function OnContactUsSuccess() {
    console.log('contact us success');
    $CONTACT_US_SUBMIT_BUTTON
        .attr('disabled', false)
        .removeClass('disabled');

    
    $CONTACT_US_FORM[0].reset();

    Toaster({
        text: CONTACT_SUCCESS_MESSAGE,
        gravity: 'bottom',
        position: 'right',
        duration: 5000,
        backgroundColor: TOASTER_SUCCESS_BG_COLOR
    });
}

// expose it to global scope.
window.OnContactUsSuccess = OnContactUsSuccess;

/*
 * ========================
 * Event handlers for CONTACT
 * US AJAX (Begin, Failure,
 * SUCCESS).
 * .\END
 * ========================
 */