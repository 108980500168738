﻿export const SUCCESS_REDIRECT_URL = '/';

export const LOGIN_ENDPOINT_URL = '/umbraco/surface/member/loginajax';

export const REGISTER_ENDPOINT_URL = '/umbraco/surface/member/registerajax';

export const LOGOUT_ENDPOINT_URL = '/umbraco/surface/member/logoutajax';

export const $REGISTER_BUTTON_ELEMENTS = $('[data-identity="register-submit-button"]');

export const $EDIT_PROFILE_BUTTON_ELEMENTS = $('[data-action="edit-profile-submit-button"]');

export const $ADDRESS_MODAL = () => window.$('#modal-address');

export const $ADDRESS_FORM = () => $('#address-form');

// the structure of html should be a <span> inside a <p>
export const $FAILED_PASSWORD_ATTEMPT_TEXT = $('[data-identity="failed-password-attempt-count-text"]');

export const MAX_FAILED_PASSWORD_ATTEMPT = 5;

export const $RESET_PASSWORD_BUTTON_ELEMENTS = $('[data-identity="reset-password]');

export const $RESET_PASSWORD_FORM_ELEMENT = $('#reset-password-form');

export const $LOGIN_BUTTON_ELEMENTS = $('[data-identity="login-submit-button"]');

export const $LOGOUT_BUTTON_ELEMENTS = $('[data-action="logout"]');

export const $SAVE_ADDRESS_BUTTON_ELEMENTS = $('[data-identity="save-address-submit-button"]');

export const $COUNTRY_INPUT_ELEMENT = $('#country-select');

export const $COUNTRY_INPUT_TEXT_ELEMENT = $('#country-input');

export const $PROVINCE_INPUT_ELEMENT = $('#province-select');

export const $PROVINCE_INPUT_TEXT_ELEMENT = $('#province-input');

export const $CITY_INPUT_ELEMENT = $('#city-select');

export const $CITY_INPUT_TEXT_ELEMENT = $('#city-input');

export const $SUBDISTRICT_INPUT_ELEMENT = $('#subdistrict-select');

export const $SUBDISTRICT_INPUT_TEXT_ELEMENT = $('#subdistrict-input');

export const $DELETE_ADDRESS_BUTTON_ELEMENT = () => $('[data-action="delete-address"]');

export const GET_ADDRESS_URL = (id) => `/umbraco/surface/member/getaddress?id=${id}`;

export const MAKE_ADDRESS_DEFAULT_URL = (id) => `/umbraco/surface/member/changedefaultaddress?id=${id}`;

export const DELETE_ADDRESS_URL = (id) => `/umbraco/surface/member/deleteaddress?id=${id}`;

export const $MAKE_ADDRESS_DEFAULT_ELEMENT = () => $('[data-action="make-address-default"]');

export const $EDIT_ADDRESS_BUTTON_ELEMENT = () => $('[data-action="edit-address"]');

export const $SHIPPING_ADDRESS_SUBMIT_BUTTOM = $('[data-action="submit-shipping-address"]');

export const LOGIN_ERROR_MESSAGE = 'THere has been an error logging you in. Please try again!';

export const REGISTER_ERROR_MESSAGE = 'There has been an error register your account. Please try again!';

export const RESET_PASSWORD_SUCCESS_MESSAGE = 'Password has been succesfully changed!';

export const $FORGOT_PASSWORD_FORM = () => $('#forgot-password-form');

export const $FORGOT_PASSWORD_SUBMIT_BUTTON = () => $('[data-identity="forgot-password-submit-button"]');

export const $RECREATE_PASSWORD_FORM = () => $('#recreate-password-form');

export const $RECREATE_PASSWORD_SUBMIT_BUTTON = () => $('[data-identity="recreate-password-submit-button"]');

//subscription const

export const $SAVE_SUBSCRIPTION = $('[data-action="save-subscription"]');

export const $RADIO_SUBSCRIPTION = () => $('[data-identity="radio-subscription"]:checked');

export const CHANGE_SUBSCRIPTION_URL = '/umbraco/surface/member/changesubscription';

export const SUCCESS_SUBSCRIBE = 'Subscription has been successfully changed!';

//mailchimp-subscription const

export const $SAVE_MAILCHIMP_SUBSCRIPTION = $('[data-action="save-mailchimp-subscription"]');

export const MAILCHIMP_SUBSCRIBE_URL = '/umbraco/surface/member/subscribeemail';

export const $MAILCHIMP = $('[data-identity="mailchimp"]');

export const SUCCESS_MAILCHIMP_SUBSCRIBE = 'Success subscribe to our newsletter!';