﻿'use strict';

import {
    common
} from '../../common';


export function FilterCategory(
    available,
    tagList,
    option1List,
    option2List,
    option3List,
    minPrice,
    maxPrice,
    sortBy,
    pageSize,
    category
) {

   this.available = available,
   this.tagList = tagList,
   this.option1List = option1List,
   this.option2List = option2List,
   this.option3List = option3List,
   this.minPrice = minPrice,
   this.maxPrice = maxPrice,
   this.sortBy = sortBy,
   this.pageSize = pageSize,
   this.category = category
}

export function createNewHrefProductFilter(FilterCategory) {
    //get params from uri
    let qParam = common.getParameterByName('q');

    let newHref = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}?`;

    if (qParam) {
        newHref = `${newHref}q=${qParam}&`;
    }
    
    if (FilterCategory.pageSize) {
        newHref = `${newHref}pageSize=${FilterCategory.pageSize}&`;
    } 

    if (FilterCategory.sortBy) {
        newHref = `${newHref}sortBy=${FilterCategory.sortBy}&`;
    }

    console.log(FilterCategory.available);

    if (FilterCategory.available.toLowerCase() == 'true') {
        newHref = `${newHref}available=${FilterCategory.available}&`;
    }
    else if (FilterCategory.available.toLowerCase() == 'false'){
        newHref = `${newHref}available=${FilterCategory.available}&`;
    }

    if (FilterCategory.tagList.length > 0) {
        newHref = `${newHref}tags=${FilterCategory.tagList.toString()}&`;
    }

    if (FilterCategory.option1List.length > 0) {
        let distinctOptName = [...new Set(FilterCategory.option1List.map(x => x.optionName))];
        let concatAll = "";
        distinctOptName.forEach(optName => {
            let optionValues = "";
            FilterCategory.option1List.forEach(x => {
                if (optName == x.optionName) {
                    if (optionValues) {
                        optionValues = optionValues.concat("," , x.optionValue);
                    }
                    else {
                        optionValues = x.optionValue;
                    }
                }
            })

            if (concatAll) {
                concatAll = concatAll.concat("&", optName, "=", optionValues);
            }
            else {
                concatAll = optName.concat("=", optionValues);
            }
        })

        newHref = `${newHref}${concatAll}&`;
    }

    if (FilterCategory.option2List.length > 0) {
        let distinctOptName = [...new Set(FilterCategory.option2List.map(x => x.optionName))];
        let concatAll = "";
        distinctOptName.forEach(optName => {
            let optionValues = "";
            FilterCategory.option2List.forEach(x => {
                if (optName == x.optionName) {
                    if (optionValues) {
                        optionValues = optionValues.concat(",", x.optionValue);
                    }
                    else {
                        optionValues = x.optionValue;
                    }
                }
            })

            if (concatAll) {
                concatAll = concatAll.concat("&", optName, "=", optionValues);
            }
            else {
                concatAll = optName.concat("=", optionValues);
            }
        })

        newHref = `${newHref}${concatAll}&`;
    }

    if (FilterCategory.option3List.length > 0) {
        let distinctOptName = [...new Set(FilterCategory.option3List.map(x => x.optionName))];
        let concatAll = "";
        distinctOptName.forEach(optName => {
            let optionValues = "";
            FilterCategory.option3List.forEach(x => {
                if (optName == x.optionName) {
                    if (optionValues) {
                        optionValues = optionValues.concat(",", x.optionValue);
                    }
                    else {
                        optionValues = x.optionValue;
                    }
                }
            })

            if (concatAll) {
                concatAll = concatAll.concat("&", optName, "=", optionValues);
            }
            else {
                concatAll = optName.concat("=", optionValues);
            }
        })

        newHref = `${newHref}${concatAll}&`;
    }

    if (FilterCategory.minPrice > 0) {
        newHref = `${newHref}minPrice=${FilterCategory.minPrice}&`;
    }

    if (FilterCategory.maxPrice > 0) {
        newHref = `${newHref}maxPrice=${FilterCategory.maxPrice}&`;
    }

    if (FilterCategory.category > 0) {
        newHref = `${newHref}category=${FilterCategory.category}&`;
    }

    newHref = newHref.substr(0, newHref.length - 1);

    return newHref;
}