﻿'use strict';

import axios from 'axios';

import {
    TOASTER_ERROR_BG_COLOR,
    TOASTER_SUCCESS_BG_COLOR,
    TOASTER_SUCCESS_BG_COLOR_ALT
} from '../../constants';

import {
    $SAVE_SUBSCRIPTION,
    $RADIO_SUBSCRIPTION,
    CHANGE_SUBSCRIPTION_URL,
    SUCCESS_SUBSCRIBE
} from './constants';

$SAVE_SUBSCRIPTION
    .click(function () {

        let email = $(this).data('email');

        let subscription = $RADIO_SUBSCRIPTION().val();

        if (subscription.toLowerCase() == "true") {
            subscription = true;
        }
        else {
            subscription = false;
        }

        let data = {
            Subscribe: subscription,
            Email: email
        }
        axios.post(CHANGE_SUBSCRIPTION_URL, data)
            .then(res => {

                if (res.status == 200) {
                    Toaster({
                        text: SUCCESS_SUBSCRIBE,
                        backgroundColor: TOASTER_SUCCESS_BG_COLOR,
                        duration: 5000,
                        gravity: 'bottom',
                        position: 'right'
                    });
                }

            });
    }); 
