﻿'use strict';

import {
    TOASTER_SUCCESS_BG_COLOR,
    TOASTER_ERROR_BG_COLOR
} from '../../constants';

import {
    $RECREATE_PASSWORD_FORM,
    $RECREATE_PASSWORD_SUBMIT_BUTTON
} from './constants';

const RECREATE_PASSWORD_ERROR_MESSAGE = 'There has been an error. Please try again!';

function OnRecreatePasswordBegin() {

    $RECREATE_PASSWORD_SUBMIT_BUTTON()
        .addClass('disabled')
        .prop('disabled', true)

}

window.OnRecreatePasswordBegin = OnRecreatePasswordBegin;

function OnRecreatePasswordFailure(err) {
    $RECREATE_PASSWORD_SUBMIT_BUTTON()
        .prop('disabled', false)
        .removeClass('disabled');

    if (err.status == 500) {

        console.error(err);

        Toaster({
            text: RECREATE_PASSWORD_ERROR_MESSAGE,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
    else {
        Toaster({
            text: err.statusText,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
}

window.OnRecreatePasswordFailure = OnRecreatePasswordFailure;

function OnRecreatePasswordSuccess(res) {
    $RECREATE_PASSWORD_FORM()
        .trigger('reset');

    $RECREATE_PASSWORD_SUBMIT_BUTTON()
        .prop('disabled', false)
        .removeClass('disabled');

    Toaster({
        text: 'Your password is successfully changed! You will be redirected in 3 seconds...',
        duration: 5000,
        backgroundColor: TOASTER_SUCCESS_BG_COLOR,
        position: 'right',
        gravity: 'bottom'
    });

    setTimeout(function () {

        window.location.href = "/login";

    }, 3000)
}

window.OnRecreatePasswordSuccess = OnRecreatePasswordSuccess;