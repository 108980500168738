﻿'use strict';

// choices js
import Choices from 'choices.js';

// constants
import {
    $SAVE_ADDRESS_BUTTON_ELEMENTS,
    $COUNTRY_INPUT_ELEMENT,
    $COUNTRY_INPUT_TEXT_ELEMENT,
    $PROVINCE_INPUT_ELEMENT,
    $PROVINCE_INPUT_TEXT_ELEMENT,
    $CITY_INPUT_ELEMENT,
    $CITY_INPUT_TEXT_ELEMENT,
    $SUBDISTRICT_INPUT_ELEMENT,
    $SUBDISTRICT_INPUT_TEXT_ELEMENT,
    $DELETE_ADDRESS_BUTTON_ELEMENT,
    $EDIT_ADDRESS_BUTTON_ELEMENT,
    DELETE_ADDRESS_URL,    GET_ADDRESS_URL,
    MAKE_ADDRESS_DEFAULT_URL,
    $ADDRESS_FORM,
    $ADDRESS_MODAL,
    $MAKE_ADDRESS_DEFAULT_ELEMENT
} from './constants';

import {
    TOASTER_SUCCESS_BG_COLOR,
    TOASTER_ERROR_BG_COLOR
} from '../../constants';

/*
 * ============================
 * Addresses inputs
 * ============================
 */
let countryChoicesInstance = undefined;

if ($COUNTRY_INPUT_ELEMENT.length) {
    countryChoicesInstance = new Choices($COUNTRY_INPUT_ELEMENT[0]);

    $.ajax({
        async: true,
        url: '/umbraco/Surface/Ecommerce/GetAllCountry',
        dataType: "json",
        type: "POST",
        contentType: "application/json; charset=utf-8",
        dataFilter: function (data) { return data; },
        success: function (data) {

            let choicesData = [];

            //Set Placeholder
            choicesData.push({
                value: '',
                label: 'country',
                selected: true,
                disabled: false
            });

            data.data.forEach(v => {
                choicesData.push({
                    value: v.id,
                    label: v.text,
                    selected: false,
                    disabled: false
                });
            });

            countryChoicesInstance.setChoices(choicesData, 'value', 'label', true);
        }
    });
}

let provinceChoicesInstance = undefined;

let cityChoicesIntance = undefined;

let subDistrictInstance = undefined;

if ($PROVINCE_INPUT_ELEMENT.length) {
    provinceChoicesInstance = new Choices($PROVINCE_INPUT_ELEMENT[0], {
        placeholderValue: 'Please choose a country',
        noChoicesText: 'Please choose a country'
    });

    countryChoicesInstance.passedElement.element.addEventListener('choice', function (event) {
        console.log(event.detail.choice.value);

        $.ajax({
            async: true,
            url: '/umbraco/Surface/Ecommerce/GetAllProvince',
            data: JSON.stringify({
                countryID: event.detail.choice.value
            }),
            dataType: "json",
            type: "POST",
            contentType: "application/json; charset=utf-8",
            dataFilter: function (data) { return data; },
            success: function (data) {
                console.log(data.data)
                if (data.data.length > 0) {
                    let choicesData = [];

                    //Set Placeholder
                    choicesData.push({
                        value: '',
                        label: 'province',
                        selected: true,
                        disabled: false
                    });

                    data.data.forEach(v => {
                        choicesData.push({
                            value: v.id,
                            label: v.text,
                            selected: false,
                            disabled: false
                        });
                    });

                    try {
                        provinceChoicesInstance.clearChoices();
                        provinceChoicesInstance.setChoiceByValue();
                        provinceChoicesInstance.setChoices(choicesData, 'value', 'label', true);
                    } catch (e) {
                        $PROVINCE_INPUT_ELEMENT.removeClass('d-none');
                        $PROVINCE_INPUT_TEXT_ELEMENT.addClass('d-none');

                        provinceChoicesInstance = new Choices($PROVINCE_INPUT_ELEMENT[0], {
                            placeholderValue: 'Please choose a province',
                            noChoicesText: 'Please choose a country',
                            choices: choicesData
                        });
                    }

                    try {
                        cityChoicesIntance.clearChoices();
                    } catch (e) {
                        $CITY_INPUT_ELEMENT.removeClass('d-none');
                        $CITY_INPUT_TEXT_ELEMENT.addClass('d-none');

                        cityChoicesIntance = new Choices($CITY_INPUT_ELEMENT[0], {
                            placeholderValue: 'Please choose a city',
                            noChoicesText: 'Please choose a province'
                        });
                    }

                    try {
                        subDistrictInstance.clearChoices();
                    } catch (e) {
                        $SUBDISTRICT_INPUT_ELEMENT.removeClass('d-none');
                        $SUBDISTRICT_INPUT_TEXT_ELEMENT.addClass('d-none');

                        subDistrictInstance = new Choices($SUBDISTRICT_INPUT_ELEMENT[0], {
                            placeholderValue: 'Please choose a subdistrict',
                            noChoicesText: 'Please choose a city'
                        });
                    }
                }
                else {
                    try {
                        provinceChoicesInstance.destroy();
                        provinceChoicesInstance = undefined;
                    } catch (e) {
                        //
                    }

                    try {
                        cityChoicesIntance.destroy();
                        cityChoicesIntance = undefined;
                    }
                    catch (e) {
                        //
                    }

                    try {
                        subDistrictInstance.destroy();
                        subDistrictInstance = undefined;
                    }
                    catch (e) {
                        //
                    }

                    $PROVINCE_INPUT_ELEMENT.addClass('d-none');
                    $PROVINCE_INPUT_TEXT_ELEMENT.removeClass('d-none');

                    $CITY_INPUT_ELEMENT.addClass('d-none');
                    $CITY_INPUT_TEXT_ELEMENT.removeClass('d-none');

                    $SUBDISTRICT_INPUT_ELEMENT.addClass('d-none');
                    $SUBDISTRICT_INPUT_TEXT_ELEMENT.removeClass('d-none');
                }
            }
        });
    });
}

if ($CITY_INPUT_ELEMENT.length) {
    cityChoicesIntance = new Choices($CITY_INPUT_ELEMENT[0], {
        placeholderValue: 'Please choose a province',
        noChoicesText: 'Please choose a province'
    });

    provinceChoicesInstance.passedElement.element.addEventListener('choice', function (event) {
        $.ajax({
            async: true,
            url: '/umbraco/Surface/Ecommerce/GetAllCity',
            data: JSON.stringify({
                provinceID: event.detail.choice.value
            }),
            dataType: "json",
            type: "POST",
            contentType: "application/json; charset=utf-8",
            dataFilter: function (data) { return data; },
            success: function (data) {
                console.log(data.data)
                let choicesData = [];

                //Set Placeholder
                choicesData.push({
                    value: '',
                    label: 'city',
                    selected: true,
                    disabled: false
                });

                data.data.forEach(v => {
                    choicesData.push({
                        value: v.id,
                        label: v.text,
                        selected: false,
                        disabled: false
                    });
                });

                try {
                    cityChoicesIntance.destroy();
                    cityChoicesIntance = new Choices($CITY_INPUT_ELEMENT[0], {
                        placeholderValue: 'Please choose a province',
                        noChoicesText: 'Please choose a province'
                    });

                    subDistrictInstance.destroy();
                    subDistrictInstance = new Choices($SUBDISTRICT_INPUT_ELEMENT[0], {
                        placeholderValue: 'Please choose a subdistrict',
                        noChoicesText: 'Please choose a city'
                    });

                    cityChoicesIntance.setChoices(choicesData, 'value', 'label', true);
                } catch (e) {
                    cityChoicesIntance = new Choices($CITY_INPUT_ELEMENT[0], {
                        placeholderValue: 'Please choose a city',
                        noChoicesText: 'Please choose a province',
                        choices: choicesData
                    });
                }

            }
        })
    });
}

if ($SUBDISTRICT_INPUT_ELEMENT.length) {
    subDistrictInstance = new Choices($SUBDISTRICT_INPUT_ELEMENT[0], {
        placeholderValue: 'Please choose a subdistrict',
        noChoicesText: 'Please choose a city'
    });

    cityChoicesIntance.passedElement.element.addEventListener('choice', function (event) {
        console.log(event.detail.choice.value);

        $.ajax({
            async: true,
            url: '/umbraco/Surface/Ecommerce/GetAllSubDistrict',
            data: JSON.stringify({
                cityID: event.detail.choice.value
            }),
            dataType: "json",
            type: "POST",
            contentType: "application/json; charset=utf-8",
            dataFilter: function (data) { return data; },
            success: function (data) {
                //if (data.data.length > 0) {
                let choicesData = [];

                //Set Placeholder
                choicesData.push({
                    value: '',
                    label: 'sub district',
                    selected: true,
                    disabled: false
                });

                data.data.forEach(v => {
                    choicesData.push({
                        value: v.id,
                        label: v.text,
                        selected: false,
                        disabled: false
                    });
                });

                try {
                    subDistrictInstance.destroy();
                    subDistrictInstance = new Choices($SUBDISTRICT_INPUT_ELEMENT[0], {
                        placeholderValue: 'Please choose a subdistrict',
                        noChoicesText: 'Please choose a city'
                    });

                    subDistrictInstance.setChoices(choicesData, 'value', 'label', true);
                } catch (e) {
                    $SUBDISTRICT_INPUT_ELEMENT.removeClass('d-none');
                    $SUBDISTRICT_INPUT_TEXT_ELEMENT.addClass('d-none');

                    subDistrictInstance = new Choices($SUBDISTRICT_INPUT_ELEMENT[0], {
                        placeholderValue: 'Please choose a subdistrict',
                        noChoicesText: 'Please choose a city',
                        choices: choicesData
                    });
                }
                //}
                //else {
                //    try {
                //        subDistrictInstance.destroy();
                //        subDistrictInstance = undefined;
                //    }
                //    catch (e) {

                //    }

                //    $SUBDISTRICT_INPUT_ELEMENT.addClass('d-none');
                //}

            }
        })
    });
}

/*
 * ============================
 * Handler for ADDRESS AJAX 
 * (Begin, Failure, Success)
 * ============================
 */
function OnSaveAddressBegin() {
    $SAVE_ADDRESS_BUTTON_ELEMENTS
        .addClass('disabled')
        .prop('disabled', true);
}

// Expose it to global scope.
window.OnSaveAddressBegin = OnSaveAddressBegin;

function OnSaveAddressFailure(err) {
    $SAVE_ADDRESS_BUTTON_ELEMENTS
        .removeClass('disabled')
        .prop('disabled', false);

    Toaster({
        text: err.statusText,
        duration: 5000,
        gravity: 'bottom',
        position: 'right',
        backgroundColor: TOASTER_ERROR_BG_COLOR,
    });
}

// Expose it to global scope.
window.OnSaveAddressFailure = OnSaveAddressFailure;

function OnSaveAddressSuccess() {
    window.location.reload();
}

// Expose it to global scope.
window.OnSaveAddressSuccess = OnSaveAddressSuccess;

/*
 * ===========================
 * Delete Address
 * ===========================
 */
function DeleteAddress(id, elem, callback) {

    $.ajax({
        url: DELETE_ADDRESS_URL(id),
        method: 'DELETE',
        context: window
    }).done(function (res, text, jqxhr) {

        Toaster({
            text: 'Address has been successfully deleted!',
            duration: 5000,
            backgroundColor: TOASTER_SUCCESS_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });

        if (callback) window[callback].call(this, id, elem)

    }).fail(function (jqxhr, text, err) {

        if (jqhxr.status === 400) {

            Toaster({
                text: text,
                duration: 5000,
                backgroundColor: TOASTER_ERROR_BG_COLOR,
                position: 'right',
                gravity: 'bottom'
            });

        }
        else {

            Toaster({
                text: text,
                duration: 5000,
                backgroundColor: TOASTER_ERROR_BG_COLOR,
                position: 'right',
                gravity: 'bottom'
            });

        }

    });

}

if ($DELETE_ADDRESS_BUTTON_ELEMENT().length) {
    $DELETE_ADDRESS_BUTTON_ELEMENT().on('click', function () {

        let id = $(this).data('id');

        let callback = $(this).data('callback');

        let prompt = $(this).data('prompt');

        if (prompt != undefined) {

            window[prompt].call(window).then(res => {

                if (res) {

                    DeleteAddress(id, $(this), callback);

                }

            });

        }
        else {

            DeleteAddress(id, $(this), callback);

        }

    });
}

/*
 * ===========================
 * Edit Address
 * ===========================
 */

function attachAddressDataToForm(id) {
    $.ajax({
        url: GET_ADDRESS_URL(id),
        method: 'get',
        context: window
    }).done(function (res, text, jqxhr) {

        console.log(res);

        $ADDRESS_FORM().find('#Id').val(res.Id);
        $ADDRESS_FORM().find('#MemberId').val(res.MemberId);
        $ADDRESS_FORM().find('#IsDefault').val(res.IsDefault);
        $ADDRESS_FORM().find('#Name').val(res.Name);
        $ADDRESS_FORM().find('#PostalCode').val(res.PostalCode);
        $ADDRESS_FORM().find('#ContactPerson').val(res.ContactPerson);
        $ADDRESS_FORM().find('#ContactNumber').val(res.ContactNumber);
        $ADDRESS_FORM().find('#AddressLine').val(res.AddressLine);

        let selectedCountry = countryChoicesInstance._currentState.choices.find(x => x.label.toLowerCase() == res.Country.toLowerCase());

        countryChoicesInstance.setChoiceByValue(selectedCountry.value);

        $.ajax({
            async: true,
            url: '/umbraco/Surface/Ecommerce/GetAllProvince',
            data: JSON.stringify({
                countryID: selectedCountry.value
            }),
            dataType: "json",
            type: "POST",
            contentType: "application/json; charset=utf-8",
            dataFilter: function (data) { return data; },
            success: function (data) {
                if (data.data.length > 0) {
                    let choicesData = [];

                    data.data.forEach(v => {
                        choicesData.push({
                            value: v.id,
                            label: v.text,
                            selected: false,
                            disabled: false
                        });
                    });

                    try {
                        provinceChoicesInstance.setChoices(choicesData, 'value', 'label', true);

                        let selectedProvince = provinceChoicesInstance._currentState.choices.find(x => x.label.toLowerCase() == res.Province.toLowerCase());

                        provinceChoicesInstance.setChoiceByValue(selectedProvince.value);

                        $.ajax({
                            async: true,
                            url: '/umbraco/Surface/Ecommerce/GetAllCity',
                            data: JSON.stringify({
                                provinceID: selectedProvince.value
                            }),
                            dataType: "json",
                            type: "POST",
                            contentType: "application/json; charset=utf-8",
                            dataFilter: function (data) { return data; },
                            success: function (data) {
                                console.log(data.data)
                                let choicesData = [];

                                data.data.forEach(v => {
                                    choicesData.push({
                                        value: v.id,
                                        label: v.text,
                                        selected: false,
                                        disabled: false
                                    });
                                });

                                try {
                                    cityChoicesIntance.setChoices(choicesData, 'value', 'label', true);

                                    let selectedCity = cityChoicesIntance._currentState.choices.find(x => x.label.toLowerCase() == res.City.toLowerCase());

                                    cityChoicesIntance.setChoiceByValue(selectedCity.value);

                                    $.ajax({
                                        async: true,
                                        url: '/umbraco/Surface/Ecommerce/GetAllSubDistrict',
                                        data: JSON.stringify({
                                            cityID: selectedCity.value
                                        }),
                                        dataType: "json",
                                        type: "POST",
                                        contentType: "application/json; charset=utf-8",
                                        dataFilter: function (data) { return data; },
                                        success: function (data) {
                                            if (data.data.length > 0) {
                                                let choicesData = [];

                                                data.data.forEach(v => {
                                                    choicesData.push({
                                                        value: v.id,
                                                        label: v.text,
                                                        selected: false,
                                                        disabled: false
                                                    });
                                                });

                                                try {
                                                    subDistrictInstance.setChoices(choicesData, 'value', 'label', true);

                                                    let selectedSubdistrict = subDistrictInstance._currentState.choices.find(x => x.label.toLowerCase() == res.Subdistrict.toLowerCase());

                                                    subDistrictInstance.setChoiceByValue(selectedSubdistrict.value);

                                                } catch (e) {
                                                    $SUBDISTRICT_INPUT_ELEMENT.removeClass('d-none');
                                                    $SUBDISTRICT_INPUT_TEXT_ELEMENT.addClass('d-none');

                                                    subDistrictInstance = new Choices($SUBDISTRICT_INPUT_ELEMENT[0], {
                                                        placeholderValue: 'Please choose a subdistrict',
                                                        noChoicesText: 'Please choose a city',
                                                        choices: choicesData
                                                    });
                                                }
                                            }
                                            else {
                                                try {
                                                    subDistrictInstance.destroy();
                                                    subDistrictInstance = undefined;
                                                }
                                                catch (e) {
                                                    //
                                                }

                                                $SUBDISTRICT_INPUT_ELEMENT.addClass('d-none');
                                            }

                                            $ADDRESS_MODAL().modal('show');
                                        }
                                    })

                                } catch (e) {
                                    cityChoicesIntance = new Choices($CITY_INPUT_ELEMENT[0], {
                                        placeholderValue: 'Please choose a city',
                                        noChoicesText: 'Please choose a province',
                                        choices: choicesData
                                    });
                                }


                            }
                        })

                    } catch (e) {
                        $PROVINCE_INPUT_ELEMENT.removeClass('d-none');
                        $PROVINCE_INPUT_TEXT_ELEMENT.addClass('d-none');

                        provinceChoicesInstance = new Choices($PROVINCE_INPUT_ELEMENT[0], {
                            placeholderValue: 'Please choose a province',
                            noChoicesText: 'Please choose a country',
                            choices: choicesData
                        });

                        $PROVINCE_INPUT_TEXT_ELEMENT.val(res.Province);
                    }

                    try {
                        cityChoicesIntance.clearChoices();
                    } catch (e) {
                        $CITY_INPUT_ELEMENT.removeClass('d-none');
                        $CITY_INPUT_TEXT_ELEMENT.addClass('d-none');

                        cityChoicesIntance = new Choices($CITY_INPUT_ELEMENT[0], {
                            placeholderValue: 'Please choose a city',
                            noChoicesText: 'Please choose a province'
                        });

                        $CITY_INPUT_TEXT_ELEMENT.val(res.City);
                    }

                    try {
                        subDistrictInstance.clearChoices();
                    } catch (e) {
                        $SUBDISTRICT_INPUT_ELEMENT.removeClass('d-none');
                        $SUBDISTRICT_INPUT_TEXT_ELEMENT.addClass('d-none');

                        subDistrictInstance = new Choices($SUBDISTRICT_INPUT_ELEMENT[0], {
                            placeholderValue: 'Please choose a subdistrict',
                            noChoicesText: 'Please choose a city'
                        });

                        $SUBDISTRICT_INPUT_TEXT_ELEMENT.val(res.Subdistrict);
                    }




                }
                else {
                    try {
                        provinceChoicesInstance.destroy();
                        provinceChoicesInstance = undefined;
                    } catch (e) {
                        //
                    }

                    try {
                        cityChoicesIntance.destroy();
                        cityChoicesIntance = undefined;
                    }
                    catch (e) {
                        //
                    }

                    try {
                        subDistrictInstance.destroy();
                        subDistrictInstance = undefined;
                    }
                    catch (e) {
                        //
                    }

                    $PROVINCE_INPUT_ELEMENT.addClass('d-none');
                    $PROVINCE_INPUT_TEXT_ELEMENT.removeClass('d-none');
                    $PROVINCE_INPUT_TEXT_ELEMENT.val(res.Province);

                    $CITY_INPUT_ELEMENT.addClass('d-none');
                    $CITY_INPUT_TEXT_ELEMENT.removeClass('d-none');
                    $CITY_INPUT_TEXT_ELEMENT.val(res.City);

                    $SUBDISTRICT_INPUT_ELEMENT.addClass('d-none');
                    $SUBDISTRICT_INPUT_TEXT_ELEMENT.removeClass('d-none');
                    $SUBDISTRICT_INPUT_TEXT_ELEMENT.val(res.Subdistrict);
                }

                $ADDRESS_MODAL().modal('show');
            }
        });


    });
}

if ($EDIT_ADDRESS_BUTTON_ELEMENT().length) {
    $EDIT_ADDRESS_BUTTON_ELEMENT().on('click', function () {

        let id = $(this).data('id');

        if (id) {

            attachAddressDataToForm(id);

        }

    });
}

/*
 * ===========================
 * Default Address
 * ===========================
 */
function changeDefaultAddres(newDefaultAddressId, elem, callback) {

    $.ajax({
        url: MAKE_ADDRESS_DEFAULT_URL(newDefaultAddressId),
        method: 'put',
        context: window
    }).done(() => {

        window.location.reload();

    }).fail((jqxhr, text, err) => {

        if (jqhxr.status == 400) {

            Toaster({
                text: text,
                duration: 5000,
                backgroundColor: TOASTER_ERROR_BG_COLOR,
                position: 'right',
                gravity: 'bottom'
            });

        }
        else {

            Toaster({
                text: err,
                duration: 5000,
                backgroundColor: TOASTER_ERROR_BG_COLOR,
                position: 'right',
                gravity: 'bottom'
            });

        }

    });

}

if ($MAKE_ADDRESS_DEFAULT_ELEMENT().length) {
    $MAKE_ADDRESS_DEFAULT_ELEMENT().on('click', function () {

        let id = $(this).data('id');

        if (id) {

            changeDefaultAddres(id);

        }

    })
}