﻿
export function AjaxRequestData(url, data) {
    console.log('Ajax Request');
    var result;

    $.ajax({
        async: false,
        url: url,
        data: JSON.stringify(data),
        dataType: "json",
        type: "POST",
        contentType: "application/json; charset=utf-8",
        dataFilter: function (data) { return data; },
        success: function (d) {
            if (d.status) {
                result = d.data;
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {

        }
    });

    return result;
}