﻿// custom form
export const $CUSTOM_FORM = $('#custom-form');

// custom form submit button
export const $CUSTOM_FORM_SUBMIT_BUTTON = $('[data-identity="custom-form-submit-button"]');

// custom submit button
export const $CUSTOM_FORM_SUBMIT_NAVI_BUTTON = $('[data-identity="custom-form-submit-button-navi"]');

// attachment
export const $CUSTOM_FORM_ATTACHMENT_1 = $('[data-identity="attachment-1"]');

export const $CUSTOM_FORM_ATTACHMENT_2 = $('[data-identity="attachment-2"]');

export const $CUSTOM_FORM_ATTACHMENT_3 = $('[data-identity="attachment-3"]');

export const $CUSTOM_FORM_ATTACHMENT_4 = $('[data-identity="attachment-4"]');

export const $CUSTOM_FORM_ATTACHMENT_5 = $('[data-identity="attachment-5"]');

//hidden files

export const $HIDDEN_ATTACHMENT_1 = $('[id="Attachment1"]');

export const $HIDDEN_ATTACHMENT_2 = $('[id="Attachment2"]');

export const $HIDDEN_ATTACHMENT_3 = $('[id="Attachment3"]');

export const $HIDDEN_ATTACHMENT_4 = $('[id="Attachment4"]');

export const $HIDDEN_ATTACHMENT_5 = $('[id="Attachment5"]');

// success message
export const CUSTOM_FORM_SUCCESS_MESSAGE = 'Thank you, we will get to you ASAP!';