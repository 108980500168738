﻿'use strict';

import {
    LAZY_LOAD_IMAGE_ELEMENTS
} from './constants';

import 'lazyload';

if (LAZY_LOAD_IMAGE_ELEMENTS().length) {
    lazyload(LAZY_LOAD_IMAGE_ELEMENTS());
}