﻿'use strict';

// import cookie tools
import Cookie from 'js-cookie';


// constants
const $MODAL = () => $('[data-identity="promo-modal"]');

const $MODAL_ACTION = () => $('[data-identity="promo-modal-action"]');

if ($MODAL().length) {

    promoModalInit();

}

function promoModalInit() {

    if (Cookie.get('promoModal') == undefined || Cookie.get('promoModal') == '0') {

        // modals
        const modals = $MODAL();

        // get the expiry day length
        const expiresLength = parseInt(modals.first().data('cookieLifetime'));


        // attaching handlers to action elements
        attachModalActionHandler(expiresLength ? expiresLength : 30);

        // when modal hidden
        modals.on('hidden.bs.modal', function (e) {

            Cookie.set('promoModal', '1', { expires: expiresLength ? expiresLength : 30 });

        });

        // set timeout to show the modal
        setTimeout(function () {
            showPromoModal(modals);
        }, 2000);

    }

}

function showPromoModal(modals) {

    modals.modal('show');

}

function attachModalActionHandler(expiresLength) {

    $MODAL_ACTION().each(function () {

        // self
        const self = $(this);

        if (self.is('button')) {

            self.on('click', function (e) {

                Cookie.set('promoModal', '1', { expires: expiresLength });

                // checking callback
                const callback = self.data('callback');

                if (callback) {

                    window[callback].call(this);
                    return;
                }
                else {

                    // checking href
                    const href = self.data('href');
                    if (href) {
                        window.location.href = href;
                    }
                }

            });

        }
        else if (self.is('input[type="text"]')) {

            self.on('keydown', function (e) {

                if (e.which === 13) {

                    Cookie.set('promoModal', '1', { expires: expiresLength });

                    // checking callback
                    const callback = self.data('callback');

                    if (callback) {

                        window[callback].call(this);
                        return;
                    }
                    else {

                        // checking href
                        const href = self.data('href');
                        if (href) {
                            window.location.href = href;
                        }
                    }

                }

            });

        }

    });

}

