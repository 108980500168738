﻿import './page-size-filter';

import './sort-by-filter';

import './category-filter';

import './product-availability-filter';

import './product-tags-filter';

import './product-option-filter';

import './product-price-filter';

import './product-filter';

import './product-set-state';

import {
    $PAGE_SIZE_FILTER_SELECT,
    $SORT_BY_FILTER_SELECT,
    $CATEGORY_FILTER_SELECT,
    $FILTER_BUTTON
} from './constants';


import {
    createNewHrefProductFilter
} from './product-filter'

import {
    getAllCheckedProductFilter
} from './product-set-state'




if ($FILTER_BUTTON().length) {

    $FILTER_BUTTON().on('click', function () {
        let category = $CATEGORY_FILTER_SELECT().val();
        let sortBy = $SORT_BY_FILTER_SELECT().val();
        let pageSize = $PAGE_SIZE_FILTER_SELECT().val();

        let allChecked = getAllCheckedProductFilter();

        allChecked.pageSize = pageSize;
        allChecked.sortBy = sortBy;
        allChecked.category = category;

        let newHref = createNewHrefProductFilter(allChecked);

        window.location.replace(newHref);
    });

}


