﻿'use strict';

import {
    $FILTER_TAGS
} from './constants';

import {
    createNewHrefProductFilter
} from './product-filter'

import {
    getAllCheckedProductFilter
} from './product-set-state'



$FILTER_TAGS.change(function () {

    let allChecked = getAllCheckedProductFilter();

    let newHref = createNewHrefProductFilter(allChecked);

    window.location.replace(newHref);

})
