﻿'use strict';

import {
    $FILTER_OPTION_1,
    $FILTER_OPTION_2,
    $FILTER_OPTION_3
} from './constants';

import {
    createNewHrefProductFilter
} from './product-filter'

import {
    getAllCheckedProductFilter
} from './product-set-state'



$FILTER_OPTION_1.change(function () {

    let allChecked = getAllCheckedProductFilter();

    let newHref = createNewHrefProductFilter(allChecked);

    window.location.replace(newHref);

});

$FILTER_OPTION_2.change(function () {

    let allChecked = getAllCheckedProductFilter();

    let newHref = createNewHrefProductFilter(allChecked);

    window.location.replace(newHref);

});

$FILTER_OPTION_3.change(function () {

    let allChecked = getAllCheckedProductFilter();

    let newHref = createNewHrefProductFilter(allChecked);

    window.location.replace(newHref);

});
