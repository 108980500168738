﻿'use strict';

import {
    $FILTER_MIN_PRICE,
    $FILTER_MAX_PRICE
} from './constants';

import {
    createNewHrefProductFilter
} from './product-filter'

import {
    getAllCheckedProductFilter
} from './product-set-state'


$FILTER_MIN_PRICE.keyup(function (e) {

    if (e.keyCode == 13) {

        let allChecked = getAllCheckedProductFilter();

        let newHref = createNewHrefProductFilter(allChecked);

        window.location.replace(newHref);

    }
})

$FILTER_MAX_PRICE.keyup(function (e) {

    if (e.keyCode == 13) {

        let allChecked = getAllCheckedProductFilter();

        let newHref = createNewHrefProductFilter(allChecked);

        window.location.replace(newHref);

    }
})

