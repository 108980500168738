﻿import Cookies from "js-cookie";
import {
    $CURRENCY
} from './constants';


$CURRENCY.change(function () {

    let $CURRENCY_CODE = $(this).find(':selected').data('option');
    let $CURRENCY_SYMBOL = $(this).find(':selected').data('symbol');
    Cookies.set("__currencyCode", $CURRENCY_CODE, { expires: 30 });
    Cookies.set("__currencySymbol", $CURRENCY_SYMBOL, { expires: 30 });

})

