﻿'use strict';

import {
    SEARCH_INPUT_ELEM,
    SEARCH_BUTTON_ELEM,
    $SEARCH_CATEGORY_ELEM
} from './constants';

if (SEARCH_INPUT_ELEM().length) {
    SEARCH_INPUT_ELEM().on('keydown', function (e) {

        if (e.which == 13) {

            let value = $(this).val();

            let isAjax = $(this).data('ajax') == 'true';

            if (isAjax) {

            }
            else {

                window.location.href = `/search?q=${value}&category=${$SEARCH_CATEGORY_ELEM.val()}`;

            }

        }

    });
}

if (SEARCH_BUTTON_ELEM().length) {
    SEARCH_BUTTON_ELEM().on('click', function (e) {

        let value = SEARCH_INPUT_ELEM().val();

        let isAjax = SEARCH_INPUT_ELEM().data('ajax') == 'true';

        if (isAjax) {

        }
        else {
            var url = `/search?q=${value}&category=${$SEARCH_CATEGORY_ELEM.val()}`;
            window.location.href = url;

        }

    });
}