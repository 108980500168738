﻿'use strict';

import Glide from '@glidejs/glide';

// constants
import {
    CAROUSEL_ELEMENTS,
    MOBILE_CAROUSEL_ELEMENTS,
    CAROUSEL_AUTOPLAY_INTERVAL
} from './constants';

// carousel
if (CAROUSEL_ELEMENTS().length) {

    CAROUSEL_ELEMENTS().forEach(carousel => {

        let isAutoplay = carousel.firstElementChild.firstElementChild.childElementCount > 1;

        let type = carousel.firstElementChild.firstElementChild.childElementCount > 1 ? 'carousel' : 'slider';

        let perview = carousel.dataset.perview ? carousel.dataset.perview : 1;

        let glide = new Glide(carousel, {
            autoplay: isAutoplay ? CAROUSEL_AUTOPLAY_INTERVAL : false,
            type: type,
            animationDuration: 800,
            animationTimingFunc: 'ease',
            gap: 0,
            perView: perview
        });

        //glide.on('build.after', function () {
        //    var slideHeight = $('.glide__slide--active').outerHeight();
        //    var glideTrack = $('.glide__track').outerHeight();

        //    console.log(slideHeight);

        //    if (slideHeight != glideTrack) {
        //        var newHeight = slideHeight;
        //        $('.glide__track').css('height', newHeight);
        //    }
        //});

        glide.on('run.after', function () {
            var slideHeight = $(carousel).find('.glide__slide--active').outerHeight();
            var glideTrack = $(carousel).find('.glide__track').outerHeight();
            if (slideHeight != glideTrack) {
                var newHeight = slideHeight;
                $(carousel).find('.glide__track').css('height', newHeight);
            }
        });

        glide.mount();
    });

}

if (MOBILE_CAROUSEL_ELEMENTS().length) {

    MOBILE_CAROUSEL_ELEMENTS().forEach(mobileCarousel => {

        let isAutoplay = mobileCarousel.firstElementChild.firstElementChild.childElementCount > 1;

        let type = mobileCarousel.firstElementChild.firstElementChild.childElementCount > 1 ? 'carousel' : 'slider';

        let perview = mobileCarousel.dataset.perview ? carousel.dataset.perview : 1;

        let glide = new Glide(mobileCarousel, {
            autoplay: isAutoplay ? CAROUSEL_AUTOPLAY_INTERVAL : false,
            type: type,
            animationDuration: 800,
            animationTimingFunc: 'ease',
            gap: 0,
            perView: perview
        });

        //glide.on('build.after', function () {
        //    var slideHeight = $('.glide__slide--active').outerHeight();
        //    var glideTrack = $('.glide__track').outerHeight();
        //    if (slideHeight != glideTrack) {
        //        var newHeight = slideHeight;
        //        $('.glide__track').css('height', newHeight);
        //    }
        //});

        glide.on('run.after', function () {
            var slideHeight = $(mobileCarousel).find('.glide__slide--active').outerHeight();
            var glideTrack = $(mobileCarousel).find('.glide__track').outerHeight();
            if (slideHeight != glideTrack) {
                var newHeight = slideHeight;
                $(mobileCarousel).find('.glide__track').css('height', newHeight);
            }
        });

        glide.mount();

    });

}
