﻿'use strict';

import {
    $PAGE_SIZE_FILTER_SELECT,
    $SORT_BY_FILTER_SELECT,
    $CATEGORY_FILTER_SELECT
} from './constants';

import {
    createNewHrefProductFilter
} from './product-filter'

import {
    getAllCheckedProductFilter
} from './product-set-state'



if ($PAGE_SIZE_FILTER_SELECT().length) {

    $PAGE_SIZE_FILTER_SELECT().on('change', function () {
        let pageSize = $(this).val();
        let sortBy = $SORT_BY_FILTER_SELECT().val();
        let category = $CATEGORY_FILTER_SELECT().val();

        let allChecked = getAllCheckedProductFilter();

        allChecked.pageSize = pageSize;
        allChecked.sortBy = sortBy;
        allChecked.category = category;

        let newHref = createNewHrefProductFilter(allChecked);

        window.location.replace(newHref);
    });

}