﻿'use strict';

import {
    TOASTER_SUCCESS_BG_COLOR,
    TOASTER_ERROR_BG_COLOR
} from '../../constants';

import {
    $FORGOT_PASSWORD_FORM,
    $FORGOT_PASSWORD_SUBMIT_BUTTON
} from './constants';

const FORGOT_PASSWORD_ERROR_MESSAGE = 'There has been an error. Please try again!';

function OnForgotPasswordBegin() {

    $FORGOT_PASSWORD_SUBMIT_BUTTON()
        .addClass('disabled')
        .prop('disabled', true)

}

window.OnForgotPasswordBegin = OnForgotPasswordBegin;

function OnForgotPasswordFailure(err) {
    $FORGOT_PASSWORD_SUBMIT_BUTTON()
        .prop('disabled', false)
        .removeClass('disabled');

    if (err.status == 500) {

        console.error(err);

        Toaster({
            text: FORGOT_PASSWORD_ERROR_MESSAGE,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
    else {
        Toaster({
            text: err.statusText,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
}

window.OnForgotPasswordFailure = OnForgotPasswordFailure;

function OnForgotPasswordSuccess(res) {
    $FORGOT_PASSWORD_FORM()
        .trigger('reset');

    $FORGOT_PASSWORD_SUBMIT_BUTTON()
        .prop('disabled', false)
        .removeClass('disabled');

    Toaster({
        text: 'An email has been sent to your email!',
        duration: 5000,
        backgroundColor: TOASTER_SUCCESS_BG_COLOR,
        position: 'right',
        gravity: 'bottom'
    });
}

window.OnForgotPasswordSuccess = OnForgotPasswordSuccess;